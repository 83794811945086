.share-panel {
  .panel;
  text-align: center;

  @media (min-width: @screen-sm-min) {
    position: static;
  }

  @share-panel-top: @navbar-height + @navbar-margin-bottom;

  @media (min-width: @screen-md-min) {
    .panel-fixed-position(@share-panel-width, @container-md, @share-panel-top);
  }
  @media (min-width: @screen-lg-min) {
    .panel-fixed-position(@share-panel-width, @container-lg, @share-panel-top);
  }

  .link {
    display: inline-block;
    font-size: @font-size-large;
    margin-bottom: @grid-gutter-width;
    background-color: @gray-dark;
    padding: 5px;
    width: 35px;
    height: 35px;

    .fa {
      color: #ffffff;
    }

    &-facebook {
      background-color: #3B5998;
    }
    &-twitter {
      background-color: #5EA9DD;
    }
    &-link {
      background-color: @brand-primary;
    }
    &-mail {
      background-color: @gray-light;
    }
    &-whatsapp {
      background-color: #30B945;
    }
  }
}
