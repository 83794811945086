.button-variant(@color; @background; @border) {
  #gradient.vertical(lighten(@background, 20%), @background);
  background-color: transparent;
  border: 1px solid transparent;

  &:focus,
  &.focus {
    #gradient.vertical(lighten(@background, 20%), @background);

    background-color: transparent;
    border-color: transparent;
  }
  &:hover {
    #gradient.vertical(lighten(@background, 20%), @background);

    background-color: transparent;
    border-color: transparent;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    #gradient.vertical(lighten(@background, 20%), @background);

    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus,
    &.focus {
      #gradient.vertical(lighten(@background, 20%), @background);

      background-color: transparent;
      border-color: transparent;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      #gradient.vertical(lighten(@background, 20%), @background);

      background-color: transparent;
      border-color: transparent;
    }
  }

}
