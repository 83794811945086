.widget(@background-color, @padding, @color) {
  position: relative;
  display: block;
  background-color: @background-color;
  padding: @padding;
  color: @color;
}

.background-cover(@img) {
  background: url(@img) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.panel-fixed-position(@panel-width, @container-width, @top: 0) {
  @panel-position: @panel-width - (@grid-gutter-width/2);

  position: fixed;
  z-index: 1;
  width: @panel-width;
  top: @top;
  left: 50%;
  margin-left: -(@container-width/2) - @panel-position;
}
