.sidebar-widget {
  position: relative;
  margin-bottom: @grid-gutter-width*3;
  padding: 0;
  overflow: hidden;
  width: 285px; // anforderung da fb-widget diese Breite liefert
  font-size: 11px;
  line-height: 14px;
  //  border: 4px solid @brand-warning-light;

  .widget-title {
    color: @brand-warning;
    margin-top: 0;
    //    position: absolute;
    //    top: -@line-height-computed;
    //    left: 12px;
    //    z-index: 2;
    //    .box-shadow(0 0 10px 0 rgba(0,0,0,0.2));
    //    .text-shadow(4, #fff);
  }

  .list-group {
    margin: 10px 5px;
    overflow: hidden;
  }

  .list-group-item.media {
    margin: 0;
    background: none;
  }

  form {
    padding: 10px;
    background: @gray-lighter;
  }
  hr {
    margin: 10px 0;
    border-color: @gray-light;
  }

  [type="text"], select, textarea {
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #000;
    width: 100%;
    background-color: #fff;
    padding: 2px 6px;
    font-size: 11px;
    color: #222;
    margin: 4px 0;
    line-height: 14px;
  }

  .captcha {
    margin: 4px auto 10px auto;
    width: 100%;
  }
}

a.widgetlink {
  background-color: #fff;
  color: #b0b0b0;
  font-size: 10px;
  font-weight: normal;
  padding: 1px 3px;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: #000;
    text-decoration: none;
  }
}

.forum-widget {
  .background-cover("@{assets-img-path}bg_ForumWidget_03.jpg");

  .widget-title {
    color: @brand-success;
  }
  .widget-body {
    border: 4px solid @brand-success;
  }
}

.new-user-widget a {
  width: 50px;
  display: block;
  float: left;
  padding: 0;
  margin: 0 1px;
  img {
    border: 1px solid #fff;
    padding: 0;
    margin: 0;
  }
}
