.list-group-item {
  border: none;
  border-bottom: 1px dashed @gray-base;
  padding: 5px;

  a {
    color: @gray-base;
    text-decoration: none;
  }
}
