// Sprücheboard

// @todo REFACTOR THIS OLD CODE

@spruch-font-size: 1.5rem;
@spruch-line-height: round(@spruch-font-size * 1.5, 2);
@spruch-box-width: 100%;

.sayings-board {
  position: relative;
  width: 100%;
  min-height: 640px;
  background: transparent url('@{assets-img-path}bg_wgtspruecheboard.jpg') no-repeat top left;
  background-size: cover;
  border: 6px solid #fff;
  padding: 1em 1em 3em 1em;

  > div {
    display: none;
  }

  > .active {
    display: block;
  }

  .widgetHeadline {
    position: absolute;
    top: -22px;
    left: 12px;
  }

  .spruch {
    font-family: 'Comic Sans MS', 'Marker Felt', cursive;
    font-size: @spruch-font-size;
    line-height: @spruch-line-height;
    margin: 18px 10px 0 10px;
    padding: 0 0 26px 0;

    .username {
      font-family: Arial, Helvetica, sans-serif;
      font-size: round(@spruch-font-size*.8 , 2);
      font-weight: bold;
      color: #005ea2;
    }
  }

  a.sprueche,
  a.back {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    height: 38px;
    width: 145px;
    overflow: hidden;
    text-indent: -999999px;
    color: #fff;
    font-size: 22px;
    border-width: 4px 0 0 4px;
    border-color: #fff;
    border-style: solid;
    border-radius: 6px 0 0 0;
    background: #e88300 url('@{assets-img-path}bt_sprueche.png') no-repeat left top;

    &:hover, &:focus, &:active {
      background-color: #f1b669;
      background-position: left bottom;
    }
  }

  a.back {
    background: #e88300 url('@{assets-img-path}bt_spruecheback.png') no-repeat left top;
  }

  .rating {
    position: absolute;
    top: 5px;
    right: 12px;
    width: 100px;
    direction: rtl;

    > a {
      .fa-icon();
      display: inline-block;
      position: relative;
      color: #ffffff;
      font-size: 18px;
      line-height: 1em;

      &:before {
        content: @fa-var-star;
        position: absolute;
        color: @gray-light;
      }
    }
    > a:hover:before,
    > a.active:before,
    > a:hover ~ a:before,
    > a.active ~ a:before {
      color: @brand-warning;
    }
  }

  .uebersichtspruchBox {
    position: relative;
    border-top: 6px solid #fff;
    background-color: rgba(255, 255, 255, 0.85);
    margin: 35px auto 0 auto;

    .entry {
      position: relative;
      border-bottom: 6px solid #fff;
      padding: 10px 20px;
    }

    .spruch {
      margin: 0;
      padding: 0;
    }

    .ratingBox {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }

    .navigation, .spruch .navigation {
      background-color: #fff;
      width: 100%;
      color: #e88300;
      font-size: 12px;
      line-height: 42px;
    }

    .navigation {
      margin: -20px 0 0 0;
      text-align: center;
      display: inline-block;
      padding: 0 30px 0 30px;

      .left {
        position: absolute;
        display: block;
        top: 50%;
        left: @grid-gutter-width;
        width: 20px;
        height: 30px;
        margin-top: -15px;
      }

      .right {
        position: absolute;
        display: block;
        top: 50%;
        left: auto;
        right: @grid-gutter-width;
        width: 20px;
        height: 30px;
        margin-top: -15px;
      }
    }
  }

  .spruecheListe {
    .navigation {
      margin: 0;
      position: relative;
    }
  }

  .spruchBox {
    position: relative;
    width: @spruch-box-width;
    margin: 3em auto;
    border-top: 6px solid #fff;
    background-color: rgba(255, 255, 255, 0.75);
    .clearfix();
  }

  .meinSpruch {
    position: relative;
    width: 100%;
    max-width: 405px;
    min-height: 95px;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 10px;
    margin: 2em 0;

    .captcha {
      position: relative;
      margin: 0;
      max-width: 250px;
      widows: 100%;
      border: 1px solid #FFF;
      padding: 1px;
      background-color: #808080;

      img {
        margin: 0;
      }

      input {
        position: absolute;
        right: 15px;
        top: 12px;
        width: 68px !important;
      }

      div {
        background: rgba(0, 0, 0, 0) url('@{assets-img-path}bg_captcha.png') repeat left top;
      }
    }

    form {
      margin: 0;
      position: relative;

      input {
        font-family: Arial, Helvetica, sans-serif;
        border: 1px solid #000;
        width: 100%;
        background-color: #fff;
        padding: 2px 6px;
        font-size: 11px;
        color: #222;
        margin: 0 0 4px 0;
        line-height: 14px;
      }
      .btn {
        margin-top: 5px;
      }

      @media (min-width: @screen-sm-min) {
        .btn {
          position: absolute;
          bottom: 0;
          width: auto;
        }

        .row {
          display: table;
          > * {
            display: table-cell;
            float: none;
          }
        }
      }
    }

    .okay, .error {
      background-color: white;
      padding: 4px;
      display: none;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      position: absolute;
      top: 112px;
      left: 11px;
      z-index: 5;
      right: 141px;
    }

    .error {
      color: @brand-danger;
      border: 4px solid @brand-danger;
    }

    .okay {
      border: 4px solid @brand-success;
      color: @brand-success;

      .close {
        display: none;
        color: #090;
        position: absolute;
        font-size: 10px;
        font-weight: normal;
        text-decoration: none;
        cursor: pointer;
        top: 4px;
        right: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .ratingBox {
    position: relative;
    left: 0;
    bottom: 0;
    background-color: #c4cfe7;
    color: #005ea2;
    font-size: 14px;
    line-height: 16px;
    padding: 5px 20px;
    min-height: 26px;
    width: 100%;

    @media (max-width: @screen-xs-max) {
      padding-left: 10px;
      padding-right: 10px;

      small {
        display: block;
      }

      .fb-like {
        position: static;
      }
    }
  }

  .paging {
    color: #e88300;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 2px;
    right: 12px;
  }

  .navigation {
    position: absolute;
    margin: -15px 0 0 0;
    width: auto;
    text-align: center;
    display: inline-block;
    padding: 0 20px 0 20px;
    z-index: 10;

    .h3 {
      .text-shadow(3px, #fff);
      color: @brand-warning;
      margin: 0;
      margin-right: 10px;
    }

    .left, .right {
      position: absolute;
      display: block;
      top: 0;
      left: auto;
      right: auto;
      width: 20px;
      height: 29px;
    }

    .left {
      left: 0;
      background: transparent url('@{assets-img-path}bt_orangearrow_left_mobile.png') no-repeat left top;
    }

    .right {
      right: 0;
      background: transparent url('@{assets-img-path}bt_orangearrow_right_mobile.png') no-repeat right top;
    }
  }

  .boxShadow {
    .box-shadow(2px 3px 15px -5px #000);
  }

  // override all facebook styles
  .fb-like {
    display: block;
    width: auto;
    height: auto;
    position: absolute;
    right: 0;
    top: 50%;
    margin: -9px 0 0 0;
  }

  .toggleView:last-child {
    display: none;
  }
}

@media (max-width: @screen-xs-max) {

  .meinSpruch {
    width: 100%;
    padding: 10px 10px 65px 10px;

    input {
      width: 100%;
    }

    .charcount {
      display: none;
    }

    .captcha {
      width: 100%;
    }
  }
}

.fb_post {
  .ajax_wait {
    position: absolute;
    right: -19px;
    top: 3px;
    visibility: hidden;

  }
  &.wait .ajax_wait {
    visibility: visible;
  }
}
