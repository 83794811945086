.form-horizontal .control-label {
  text-align: left;
  font-weight: normal;
}

.form-control {
  border-radius: 4px;
}

.required:before {
  content: '*';
  position: absolute;
  display: inline-block;
  left: -3px;
}

input[type="file"] {
  &.invisible {
    opacity: 0;
    height: 1px;
  }
}

textarea.form-control {
  resize: none;
}
