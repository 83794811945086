.lazyLoadTeaser {
  .loading-indicator {
    margin-left: .5em;
    display: none;
  }

  &.loading {
    .loading-indicator {
      display: inline-block;
    }
  }
}
