html {
  overflow-y: scroll;
}

body {
  padding-top: @navbar-height + @grid-gutter-width;
  background: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /*
  Hack für Bootstrap Modal, da in Version 3.3.5 per JavaScript Padding hinzugefügt wird
  */
  &.modal-open {
    padding-right: 0 !important;
  }

  @media (max-width: @screen-xs-max) {
    position: relative;
    padding-top: 0;
    padding-left: 0;
    width: 100%;
    overflow-x: hidden;
    .transition(all 0.5s ease);

    &.nav-expanded {
      margin-left: @mobile-menu-expanded-width;
      .sidebar-nav {
        left: -@mobile-menu-expanded-width;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    //background : url("../img/wallpaper_haefft_20-jahre-haefft_opacity35.jpg") no-repeat center center fixed
  }
}

a {
  color: @link-color;
  text-decoration: @link-decoration;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
  }
}

.bold, strong {
  font-weight: bold;
}

.content {
  position: relative;
  .panel {
    position: relative;
    //z-index: 2; // weiß nich was das hier zu suchen hat
  }
}

.gray-box {
  padding: 10px;
  background: @gray-lighter;
}

.article-content {
  img {
    margin-bottom: 10px;
    .img-responsive;
  }
}

.responsive-adv-banner {
  img {
    .img-responsive();
  }
}
