.listicle-item {
  margin-top: 40px !important;

  .listicle-number {
    font-family: @font-family-brand;
    font-style: italic;
    font-size: 60px;
    line-height: 1;
    color: #bfbfbf;
  }
  .listicle-text {
    color: @gray;
    font-size: @font-size-large;
    font-weight: bold;
    min-height: 50px;
  }
}
