@media screen and (min-width: 1px) and (max-width: @screen-xs-max) {
  [data-columns]::before {
    content: '2 .col-xs-6';
    display: inline-block;
    height: 0;
    overflow: hidden;
  }
}

@media (min-width: @screen-sm-min) {
  [data-columns]::before {
    content: '3 .col-sm-4';
    display: inline-block;
    height: 0;
    overflow: hidden;
  }
}

