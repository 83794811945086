.navbar {
  font-family: @font-family-brand;
  font-style: italic;
  font-size: ceil((@font-size-base * 1.5));

  .navbar-link {
    display: inline-block;
    margin: 0 5px;
  }

  .profile-image {
    display: inline-block;
    width: @profile-image-width;
    height: @profile-image-width;
    .navbar-text;
    .navbar-vertical-align(@profile-image-width);

    img {
      width: @profile-image-width;
      height: @profile-image-width;
      border: 1px solid #ffffff;
    }
    @media (max-width: @screen-xs-max) {
      position: absolute;
      top: 40px;
      right: 10px;
    }
  }

  .dropdown-menu {
    font-size: 18px;
  }
}

.navbar-nav {
  > li {
    .dropdown-menu {
      min-width: 0;
      > li > a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    @media (min-width: @screen-sm-min) {
      &.active > a {
        border-bottom: 2px solid @brand-warning;
      }

      &:hover, &.active:hover,
      &.open {
        .dropdown-toggle {
          position: relative;
          z-index: @zindex-dropdown+1;
        }
        .dropdown-menu {
          display: block;
        }
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: @grid-float-breakpoint) {
    float: left;
    margin: 0;

    > li {
      .navbar-vertical-align(20px);
      padding: 0 @navbar-padding-horizontal;
      float: left;
      > a {
        padding: 0;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    > li {
      > a {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    .open .dropdown-menu {
      padding: 0;
      .box-shadow(inset 1px 1px 15px 0 rgba(0, 0, 0, .45));
      background-color: @brand-warning;
      > li > a {
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: @line-height-computed;
        font-size: 16px;
      }
    }

    &.navbar-right {
      position: absolute;
      margin: 0;
      top: 8px;
      right: 0;
      padding: 0;
    }
  }
}

.navbar-inverse {
  #gradient.vertical(#1275b7, #185ea6);
  .box-shadow(1px 1px 15px 0 rgba(0, 0, 0, .45));

  border: none;
  .navbar-brand {
    @brand-padding: @navbar-padding-vertical/2 - 4;
    padding-top: @brand-padding;
    padding-bottom: @brand-padding;

    img {
      .img-responsive();
      max-height: @navbar-height - (@brand-padding*2);
    }
  }
  a {
    color: @navbar-inverse-link-color;
    text-decoration: none;
  }
}

.navbar-inverse .navbar-toggle {
  margin-left: @navbar-padding-horizontal;
  margin-right: @navbar-padding-horizontal;
  padding: 8px 5px;
  .navbar-vertical-align(36px);
  background-color: transparent;

  &:hover, &:focus {
    background-color: transparent;
    .icon-bar {
      background-color: @navbar-inverse-toggle-hover-bg;
    }
  }
  .icon-bar {
    width: 22px;
    height: 3px;
    border-radius: 3px;
    background-color: @navbar-inverse-toggle-bg;
    .box-shadow(0 0 3px 0 rgba(0, 0, 0, 0.6));
  }
}

.navbar-form {
  @input-padding: 2px;
  @input-height: 33px;

  .navbar-vertical-align(@input-height);
  font-family: @font-family-base;

  .btn {
    .input-size(@input-height; @input-padding; (@input-padding*2); @font-size-small; @font-size-small; @input-border-radius-small);
  }

  .form-group {
    display: none;
    .btn {
      color: @gray-light;
      background-color: #fff;
      .box-shadow(none);

      &:hover, &:focus, &:active {
        color: @brand-primary;
      }
    }
    .form-control {
      .input-size(@input-height; @input-padding; (@input-padding*2); @font-size-small; @input-height; @input-border-radius-small);
      .box-shadow(none);
      border-color: #ffffff;
    }
  }

  &.active {
    .form-group {
      display: inline-block;
    }
    > .btn {
      display: none;
    }
  }

}

.main-foot .navbar {
  font-family: @font-family-base;
  font-size: @font-size-small;
  margin: 0;

  .list-unstyled {
    margin: 10px 0;

    li {
      display: inline-block;
      &:after {
        content: "|";
        color: #ffffff;
        margin: 0 5px;
      }
      &:last-child:after {
        content: "";
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  .navbar-fixed-top {
    position: relative;
    &.container {
      padding: 0;
    }

    .navbar-collapse {
      max-height: 2000px;
      height: auto;
      position: absolute;
      width: 100%;
      background-color: @brand-primary;
    }
  }
}

.navbar-text {
  text-align: right;
  margin-top: 0;
}

@media (min-width: @screen-sm-min) {
  .navbar-text {
    text-align: left;
    .navbar-vertical-align(30px);
  }
}
