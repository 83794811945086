// generates big text shadow strokes
.text-shadow(@stroke, @color) {
  @maxi: @stroke;
  @mini: -@stroke;
  .i-loop (@i) when (@i > @mini) {

    @maxj: @stroke;
    @minj: -@stroke;
    .j-loop (@j) when (@j > @minj) {
      text-shadow+: (1px*@i) (1px*@j) 0 @color; // code for each iteration
      .j-loop(@j - 1);
    }
    .j-loop (0) {
    }
    .j-loop(@maxj);

    .i-loop(@i - 1);
  }
  .i-loop (0) {
  }
  .i-loop(@maxi);
}


