.thumbnail {
  .box-shadow(@thumbnail-shadow);

  &[href]:hover,
  &[href]:focus,
  &[href].active {
    text-decoration: none;
    color: red;
  }

  .caption {
    line-height: 1.5em;
    height: 3em;
    font-family: @font-family-brand;
    font-style: italic;
    color: @brand-primary;
    font-size: @font-size-h5;
    padding: 0;
    overflow: hidden;
  }
}

.img-thumbnail {
  .box-shadow(@thumbnail-shadow);
  margin-bottom: @grid-gutter-width;
  padding: @thumbnail-padding;
}

.media {
  overflow: visible;
  img {
    border: 1px solid #ffffff;
  }
  .media-object {
    padding: @thumbnail-padding;
    line-height: @line-height-base;
    background-color: @thumbnail-bg;
    border: 1px solid @thumbnail-border;
    .box-shadow(@thumbnail-shadow);
  }
}

.profile-img-preview {
  position: relative;
  margin-bottom: @grid-gutter-width/2;
  .btn {
    position: absolute;
    left: @thumbnail-padding;
    bottom: @thumbnail-padding;
    top: auto;
    right: auto;
  }

  .btn-close {
    top: @thumbnail-padding;
    right: @thumbnail-padding;
    left: auto;
    bottom: auto;
  }
}
