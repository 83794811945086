.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-bottom: 20px;
}

.p20 {
  padding: 20px 0;
}

.media {
  margin-top: 10px;
}

.img-responsive {
  margin-left: auto;
  margin-right: auto;
}

.dl-horizontal dt {
  text-align: left;
}

.article-header {
  position: relative;
  .caption {
    position: absolute;
    background: @brand-warning;
    color: #ffffff;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 5px 10px;
  }
}

.captcha {
  width: 250px;
  border: 1px solid #fff;
  padding: 1px;
  background-color: #808080;
  position: relative;
  margin: 10px auto;

  div {
    background: transparent url('@{assets-img-path}bg_captcha.png') repeat left top;
  }
  input {
    position: absolute;
    right: 15px;
    top: 12px;
    border: 1px solid #424242;
    width: 68px !important;
    background-color: #fff;
    padding: 2px 4px;
    margin: 0;
    font-size: 11px;
    line-height: 14px;
  }
}

.haeftige-aktion-widget img {
  width: 100%;
}

.kleingedrucktes-list {
  .media-body {
    position: relative;
  }
  .btn-remove {
    position: absolute;
    right: -@padding-base-horizontal;
    top: -@padding-base-vertical;
  }
}
