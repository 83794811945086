.favorite-sayings {
  border: none;
  border-bottom: 31px solid white;
  position: relative;
  width: 100%;
  height: auto;
  top: 20px;
  margin: 10px 0 48px 0;
  background: none;
  background-size: cover;
  .box-shadow(@panel-shadow);

  .wgt_img {
    border: 4px solid #FFF;
    box-sizing: border-box;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  .navigation {
    position: absolute;
    margin: -20px 0 0;
    width: auto;
    text-align: center;
    display: inline-block;
    padding: 0 20px 0;
    z-index: 10;
    left: 11px;

    .left {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 40px;
      height: 49px;
      background: transparent url('@{assets-img-path}bt_orangearrow_left_mobile.png') no-repeat left top;
    }

    .right {
      position: absolute;
      display: block;
      top: 0;
      left: auto;
      right: 0;
      width: 40px;
      height: 49px;
      background: url("@{assets-img-path}bt_orangearrow_right_mobile.png") no-repeat scroll right top transparent;
    }
  }

  .spruchwrapper {
    margin: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 3% 0 3.5% 0;
  }

  .spruch {
    color: #a24407;
    font-size: 34px;
    line-height: 38px;
    margin: 0;
    padding: 1.5% 18% 2% 4%;
    font-family: Myriad, sans-serif;
  }

  .fb_post {
    position: absolute;
    bottom: -25px;
    left: 8px;
    text-decoration: none;
    display: block;
  }

  .fb-like {
    position: absolute;
    bottom: -25px;
    right: 8px;
    z-index: 9;
    width: auto !important;
    height: 20px !important;
  }
}

.favorite-sayings-widget .favorite-sayings {
  margin: 0;
  top: 0;
  .box-shadow(none);

  .wgt_img {
    border-left: none;
    border-right: none;
  }
  .navigation {
    @navigation-size: 30px;
    width: 100%;
    margin: 0;
    padding: 0;
    top: 50%;
    margin-top: -@navigation-size/2;
    left: 0;

    h3 {
      display: none;
    }

    .left, .right {
      width: @navigation-size;
      height: @navigation-size;
      background-position: center center;
    }
    .left {
      left: -@navigation-size/2;
    }
    .right {
      right: -@navigation-size/2;
    }
  }
  .fb_post {
    left: 0;
  }
  .fb-like {
    right: 0;
  }

}
