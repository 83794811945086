/*@font-face {
  font-family: 'The Sans OS';
  src: url('@{custom-font-path}thesansosf-extrabolditalic-webfont.eot');
  src: url('@{custom-font-path}thesansosf-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('@{custom-font-path}thesansosf-extrabolditalic-webfont.woff') format('woff'), url('@{custom-font-path}thesansosf-extrabolditalic-webfont.ttf') format('truetype'), url('@{custom-font-path}thesansosf-extrabolditalic-webfont.svg#thesansosfextrabold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../fonts/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

.page-title, .text-highlight {
  color: @brand-primary;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-style: italic;
}

h1 {
  margin-top: 10px;
}

.longtitle {
  font-family: @font-family-base;
  font-weight: bold;
  font-size: @font-size-large;
  color: @text-color;
  margin-bottom: 20px;
}
