.panel {
  .box-shadow(@panel-shadow);
  display: block;
}

.item {
  .box-shadow(@panel-shadow);
  .widget(#ffffff, 0, @brand-primary);
  .transition(~"box-shadow ease-in-out .15s");
  margin-bottom: @grid-gutter-width;
  text-decoration: none;

  &:hover, &:active, &:focus, &:visited {
    .box-shadow(@panel-shadow-hover);
    text-decoration: none;
  }

  .item-body {
    padding: @grid-gutter-width/2;

    > *:last-child {
      margin-bottom: 0;
    }
  }
  .item-title {
    margin: 0;
    font-size: @font-size-h4;
    margin-bottom: 10px;
    overflow: hidden; /* versteckt lange Titel */
  }
  img {
    .img-responsive;
  }
}
